@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.boiler-list {
  table {
    width: 100%;
    border: 1px solid lightgrey;

    th {
      padding: .25rem 0 .25rem .5rem;
      background-color: $gray-300;
      user-select: none;
    }

    td {
      padding: .25rem .5rem;
    }

    tbody tr {
      &:nth-child(2n) td {
        background-color: $gray-100;
      }

      &:hover td {
        background-color: $gray-200;
      }
    }
  }

  .sort-icon {
    display: inline-block;
  }

  &__pagination {
    text-align: right;
  }
}
