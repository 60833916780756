.auth-page {
  display: block;
  max-width: 280px;
  margin: 2rem auto;
  text-align: center;

  &__title {
    margin-bottom: 1rem;
    font-size: 24px;
    font-weight: normal;
  }

  &__set-view {
    display: block;
    margin-top: 12px;
    text-align: center;
    color: white;
  }

  .boiler-modal{
    color:black;
  }
}
