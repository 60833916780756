.logo-with-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    display: block;
    margin-top: 2px;
    font-size: 13px;
    font-weight: bold;
  }
}
