.loading {
  margin: 0 auto;
  height: 20px;
  width: 20px;

  .spinner-border {
    height: 100%;
    width: 100%;
  }
}
