.sign-up {
  .form-group {
    margin: 0;
    padding: 0 0 .25rem;

    .form-label {
      margin: 0 0 .25rem;
    }

    .form-control {
      margin: 0;
    }
  }
}
