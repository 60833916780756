.error-page {
  display: block;
  margin: 0 auto;
  max-width: 420px;
  padding: 2rem;
  text-align: center;

  img {
    width: 100%;
    margin: 40px 0;
  }
}
