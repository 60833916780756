.boiler-modal {
  display: none;

  &.show {
    display: block;
  }

  .modal {
    display: block;
  }
}
