@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.boiler-monitoring {
  display: flex;
  flex-direction: column;
  height: 100%;

  .boiler-monitoring-table {
    display: block;
    flex: 1 0 auto;
    border: 1px solid $gray-200;
    color: $text-muted;
    font-size: .8rem;
    overflow: auto;

    .tr {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray-200;

      &:last-child {
        border-bottom: none;
      }
    }

    .td {
      width: 50%;
      padding: .5rem 1rem;
    }
  }
}
