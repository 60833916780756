@import "src/variables";
@import "~bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  padding: 0;
  color: white;
  background-color: $primary-dim;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue;
}

.page {
  border-radius: .25rem;
  padding: 1rem 1.25rem;
  color: $gray-800;
  background-color: white;
}

.btn-secondary{
  color:white;
}

.btn-link{
  color:white;
}
