.sign-in {
  .form-group .form-control {
    height: auto;
    margin: 0;
    padding: .5rem 1rem;
    appearance: none;
    border: 0;

    &:first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top: 1px solid #ccc;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
