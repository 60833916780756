@import "src/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.header {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 8px 0;
  background-color: white;

  &__logo {
    flex: 0 0 25%;

    &__image {
      width: 121.5px;
      height: 30px;
    }
  }

  &__social-assist-logo {
    flex: 0 0 50%;
  }

  &__buttons {
    flex: 0 0 25%;
    text-align: right;
  }
}
